import axios from 'axios';
import {CacheData} from '../cache/Cache';
import AsyncStorage from "@react-native-async-storage/async-storage" ;
import URLHelper from '../URLHelper'
import NetInfo from "@react-native-community/netinfo";
import { Alert } from 'react-native'
export default class Api {  
// get is a static method that retrieves data from cache or fetch it from a remote server.
// The method takes in a key argument to identify the data being requested.
// If the device is connected, it attempts to retrieve the data from cache.
// If the data is found in the cache and is still fresh, it returns the cached data.
// If the cache is stale or the data is not found in cache, it fetches the data from a remote server,
// stores it in cache along with a timestamp, and returns the response.
static async fetch(url) {
    return NetInfo.fetch().then((state) => {
     if(state.isConnected && state.isInternetReachable) {
       try {
         return axios.get(`${URLHelper.prefix}${url}`)
         .then((response) => {
           return response.data
         }).catch((e) => {
         console.log(e)
         return e
         })
       } catch (e) {
         console.log(e)
         return null 
       } 
     } else { 
       return null 
     }
})
}


static async fetchOrCache(key) {
  const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
  const state = await NetInfo.fetch()
  return new Promise(async (resolve, reject) => {
    try {
      const data = await AsyncStorage.getItem(key);
      if (data) {
        const cacheData = JSON.parse(data);
        const currentTime = new Date().getTime();
        if (currentTime - cacheData.timestamp < ONE_DAY_IN_MILLISECONDS) {
          resolve(cacheData.data);
        } else {
          if(state.isConnected && state.isInternetReachable) {
          const response = await axios.get(`${URLHelper.prefix}${key}`);
          const updatedData = {
            data: response.data,
            timestamp: currentTime
          };
          AsyncStorage.setItem(key, JSON.stringify(updatedData));
          resolve(response.data);
          }
        }
      } else {
        if(state.isConnected && state.isInternetReachable) {
        const response = await axios.get(`${URLHelper.prefix}${key}`);
        const updatedData = {
          data: response.data,
          timestamp: new Date().getTime()
        };
        AsyncStorage.setItem(key, JSON.stringify(updatedData));
        resolve(response.data);
        }
      }
    } catch (error) {
      console.log(error)
      reject(error);
    }
  });
}
static async get(key, where) {
  const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
  const state = await NetInfo.fetch()
  return new Promise(async (resolve, reject) => {
    try {
      const data = await AsyncStorage.getItem(key);
      if (data) {
        const cacheData = JSON.parse(data);
        const currentTime = new Date().getTime();
        if (currentTime - cacheData.timestamp < ONE_DAY_IN_MILLISECONDS) {
          resolve(cacheData.data);
        } else {
          if(state.isConnected && state.isInternetReachable) {
          const response = await axios.post(`${URLHelper.prefix}${key}`, where);
          const updatedData = {
            data: response.data,
            timestamp: currentTime
          };
          AsyncStorage.setItem(key, JSON.stringify(updatedData));
          resolve(response.data);
          }
        }
      } else {
        if(state.isConnected && state.isInternetReachable) {
        const response = await axios.post(`${URLHelper.prefix}${key}`, where);
        const updatedData = {
          data: response.data,
          timestamp: new Date().getTime()
        };
        AsyncStorage.setItem(key, JSON.stringify(updatedData));
        resolve(response.data);
        }
      }
    } catch (error) {
      reject(error);
    }
  });
}
static async fetchAdmin(key, username, password) {
  return new Promise(async (resolve, reject) => {
    try {
       const response = await axios.post(`${URLHelper.prefix}${key}`, {username: username, password: password });
        if(response.data) {
          await AsyncStorage.setItem(key, JSON.stringify(response.data));
          resolve(response.data);
        }
    } catch (error) {
      reject(error);
    }
  });
}
static async getOrCache(key) {
  const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
  return new Promise(async (resolve, reject) => {
    try {
      const isConnected = await NetInfo.fetch().then(state => state.isConnected);
      const data = await AsyncStorage.getItem(key);
      if (data) {
        const cacheData = JSON.parse(data);
        const currentTime = new Date().getTime();
        if (currentTime - cacheData.timestamp < ONE_DAY_IN_MILLISECONDS  ) {
          resolve(cacheData.data);
        } else {
          const response = await axios.get(`${URLHelper.prefix}${key}`);
          const updatedData = {
            data: response.data,
            timestamp: currentTime
          };
          AsyncStorage.setItem(key, JSON.stringify(updatedData));
          resolve(response.data);
        }
      } else {
        const response = await axios.get(`${URLHelper.prefix}${key}`);
        const updatedData = {
          data: response.data,
          timestamp: new Date().getTime()
        };
        AsyncStorage.setItem(key, JSON.stringify(updatedData));
        resolve(response.data);
      }
    } catch (error) {
      reject(error);
    }
  });
}
static async postOrCache(key, data) {
  const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
  return new Promise(async (resolve, reject) => {
    try {
      const isConnected = await NetInfo.fetch().then(state => state.isConnected);
      const data = await AsyncStorage.getItem(key);
      if (data) {
        const cacheData = JSON.parse(data);
        const currentTime = new Date().getTime();
        if (currentTime - cacheData.timestamp < ONE_DAY_IN_MILLISECONDS ) {
          resolve(cacheData.data);
        } else {
          const response = await axios.post(`${URLHelper.prefix}${key}`, data);
          const updatedData = {
            data: response.data,
            timestamp: currentTime
          };
          AsyncStorage.setItem(key, JSON.stringify(updatedData));
          resolve(response.data);
        }
      } else {
        const response = await axios.post(`${URLHelper.prefix}${key}`, data);
        const updatedData = {
          data: response.data,
          timestamp: new Date().getTime()
        };
        AsyncStorage.setItem(key, JSON.stringify(updatedData));
        resolve(response.data);
      }
    } catch (error) {
      reject(error);
    }
  });
}
static async postInvest(data) {
 let insertedData = null
   insertedData =  await axios.post(URLHelper.invest, data)
   .then((response) => {
     return AsyncStorage.getItem('investors').then((oldData) => {
      const oldContrubitorsData = JSON.parse(oldData)
      const newContributorData = response.data
      const mergedData = oldContrubitorsData.data.concat(newContributorData)
       AsyncStorage.setItem('investors', JSON.stringify({data: mergedData}))
       return newContributorData
      })
    })
   .catch((e) => console.log(e))
    return insertedData
}

static async deleteContribution(id, storeKey) {
 return NetInfo.fetch().then((state) => {
    if(state.isConnected && state.isInternetReachable) {
     return axios.post(`${URLHelper.prefix}investors/delete`, {id: id})
      .then((response) => {
        return response.data
      })
      .catch(e => {
        console.log(e)
        Alert.alert('Technical Error:', 'Something went wrong, please check your network and try again later or contact the developer of this app abass@abassdev.com')
      })
    } else {
      Alert.alert('Error network:', 'Operation failed! Please check your network and try again.')
      return 
    }
  })
}
}

export async function FetchData(url) {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function FetchPostData(url, post) {
  try {
    const response = await axios.post(url, post);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}


export async function GetAdmin(url, body) {
    const getDatas = async () => {
      NetInfo.fetch().then( async (state) => { 
        if(state.isConnected && state.isInternetReachable) {
          
    try {
      const cachedData = await AsyncStorage.getItem('admin');
      if (cachedData) {
        return JSON.parse(cachedData)
      } else {
        const fetchedData = await FetchPostData(url, body);
          CacheData('admin', fetchedData);
          return fetchedData;
      }
    } catch (error) {
      console.error(error);
    }
        } else {
          alert('no net')
        }
      })
    
  };
   return getDatas()
}

export async function logOutAdmin(cacheKey) {
    const getDatas = async () => {
    try {
      await AsyncStorage.removeItem(cacheKey);
    } catch (error) {
      console.error(error);
    }
  };
   return getDatas()
}

export async function isAdmin() {
  const cachedData = await AsyncStorage.getItem('admin');
      if(cachedData != null) {
        return true
      } else return false 
}

export async function getCurrentAdmin() {
   const cachedData = await AsyncStorage.getItem('admin');
      if (cachedData != null) {
        return JSON.parse(cachedData);
      } else {
        return null;
      }
}
