import { useEffect, useState, useContext } from "react";
import {
  View,
  Text,
  Dimensions,
  FlatList,
  StyleSheet,
  ScrollView,
  Image,
  TouchableOpacity,
  Pressable,
} from "react-native";
import { useTranslation } from "react-i18next";
import "../i18n";
import { FontAwesome } from "@expo/vector-icons";
import OpenLink from "./util/OpenLink";
import { LinearGradient } from "expo-linear-gradient";
import { AuthContext } from "./auth/AuthProvider";
import Api from './api/Api'
import Loading from './ui/Loading'
import CustomAlert from './CustomAlert'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useNavigation } from '@react-navigation/native'

export default function DataItem({ data, year, month }) {
  const [displayEvent, setDisplayEvent] = useState(false);
  const { t, i18n } = useTranslation();
  const [isLoading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('Loading...')
  const AuthCtx = useContext(AuthContext);
  const navigation = useNavigation()
  useEffect(() => {
    AuthCtx.authenticate();
  }, []);
  
  function onEditItemHandler(id) {
    const itemToUpdate = data.filter((item) => {
      return item.id == id
    })
    if(itemToUpdate) {
     navigation.navigate('NewRecordScreen', {data: itemToUpdate})
  }
  }
  function onDeleteItemHandler(id) {
    const deleteId = data.filter((item) => {
      return item.id == id
    })
    if(!isNaN(deleteId[0].id)) {
      CustomAlert('Warning:', "Delete record with the following information:\n " +
      "\n ID: " + deleteId[0].id +
      "\n Full name: " + deleteId[0].full_name +
      "\n Amount: " + Math.round(deleteId[0].amount) + 'F' +
      "\n Inserted date: " +  new Date(deleteId[0].time).toLocaleString()
      , [
        {text: 'Confirm', onPress: () => { 
        try {
          setLoading(true)
          setLoadingMessage('Deleting...')
          Api.deleteContribution(deleteId[0].id, 'investors')
          .then((value) => {
            if(value && value.status == 'success') {
              setLoading(true)
              setLoadingMessage('Reloading...')
              const removeStoreItem = data.filter(item => item.id !== id)
              AsyncStorage.setItem('investors', JSON.stringify({data: removeStoreItem}))
              .then((value) => {
                setLoading(false)
                CustomAlert('Success:', 'Record deleted successfully! click OK to reload this page.', [
                  {text: "Reload later", style: 'cancel'},
                  {text: 'OK', onPress: () => navigation.replace('InvestorsDataScreen', {year: year})}
                  ])
              })
            }
            else if(value && value.status == 'failed') {
              setLoading(false)
              CustomAlert('Failed:', "This record doesn't exists in the database")
            }
          })
        } catch (error) {
          CustomAlert('Unknown Error:', 'Something went wrong, please check your network and try again later.')
          console.log(error)
        } finally {
          setLoading(false)
        }
        }},
        {text: 'Cancel', style: 'cancel'}
        ])
    }
  }
  
  const monthName = [
    "Init",
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ];
  const monthData = data.filter((item) => {
    const date = new Date(item.time);
    return date.getFullYear() === year && date.getMonth() + 1 === month;
  });
  let sum = 0;
  for (let i = 0; i < monthData.length; i++) {
    sum += parseFloat(monthData[i].amount);
  }

  function toggleHandler() {
    setDisplayEvent(!displayEvent);
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  if(isLoading) {
    return <Loading message={loadingMessage}/>
  }
  if (year == 2022 || month <= new Date().getMonth() + 1) {
    return (
      <>
        <TouchableOpacity
          style={styles.titleConrainer}
          onPress={() => {
            setDisplayEvent(!displayEvent);
          }}
        >
          <Text style={styles.title}>{monthName[month]}</Text>
          <Text style={styles.title}>
            {monthData[0] ? (
              <Text
                style={{
                  color: "rgb(0,208,255)",
                  fontSize: 20,
                  fontFamily: "monospace",
                  fontWeight: "bold",
                }}
              >
                {sum + "F"}
              </Text>
            ) : (
              ""
            )}
          </Text>
        </TouchableOpacity>

        <FlatList
          style={{ display: displayEvent ? "flex" : "none" }}
          data={monthData}
          renderItem={({ item, index }) => {
            return (
              <Pressable
                key={item.id}
                onPress={() => {}}
                android_ripple={{ color: "black" }}
                style={styles.itemPressEffect}
              >
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 5,
                    zIndex: 1,
                  }}
                >
                  <Image
                    style={{ width: 100, height: 100, borderRadius: 100 }}
                    source={{ uri: item.img }}
                  />
                </View>
                <LinearGradient
                  // Background Linear Gradient
                  colors={["#fff", "#00ccff50"]}
                  style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    //transform: [{scale: 3}],
                    height: "100%",
                  }}
                />
                <View style={{ flex: 1, backgroundColor: "#ffffff50" }}>
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 5,
                      borderBottomColor: "#dbdbdb",
                      borderBottomWidth: 3,
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        color: "#666",
                        fontWeight: "bold",
                        fontSize: 17,
                      }}
                    >
                      {item.full_name}
                    </Text>
                    {AuthCtx.isLogin && (
                      // Control buttons if logged in as admin
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Pressable
                          onPress={() => onEditItemHandler(item.id)}
                          android_ripple={{ color: "#5c5c5c" }}
                          style={{ marginRight: 9, paddingTop: 3 }}
                        >
                          <FontAwesome name="edit" color="blue" size={18} />
                        </Pressable>
                        <Pressable
                          onPress={() => onDeleteItemHandler(item.id)}
                          android_ripple={{ color: "#5c5c5c" }}
                        >
                          <FontAwesome name="remove" color="#ff43b1" size={20} />
                        </Pressable>
                      </View>
                    )}
                  </View>
                  <Text style={styles.cardItemText}>
                    <FontAwesome size={20} name="id-card-o" /> {item.id}
                  </Text>
                  <Text style={styles.cardItemText}>
                    <FontAwesome size={20} name="user" /> {item.username}
                  </Text>
                  <Text style={styles.cardItemText}>
                    <FontAwesome size={20} name="money" />{" "}
                    {Math.round(item.amount)}f
                  </Text>
                  {item.tel && (
                    <Pressable
                      onPress={() => {
                        OpenLink("https://wa.me/:" + item.tel);
                      }}
                    >
                      <Text style={[styles.cardItemText, { color: "blue" }]}>
                        <FontAwesome size={20} name="whatsapp" />{" "}
                        {"+" + item.tel}
                      </Text>
                    </Pressable>
                  )}
                  <Text style={styles.cardItemText}>
                    <FontAwesome size={20} name="clock-o" />{" "}
                    {new Date(item.time).toLocaleString()}
                  </Text>
                </View>
              </Pressable>
            );
          }}
        />
      </>
    );
  } else {
    return null;
  }
  
}

const windowWidth = Dimensions.get("window").width;

const styles = StyleSheet.create({
  panel: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#ececec",
    paddingTop: 10,
  },
  title: {
    fontSize: 27,
    fontFamily: "monospace",
  },
  titleConrainer: {
    paddingHorizontal: 10,
    paddingVertical: 15,
    borderBottomWidth: 2,
    borderBottomColor: "#dbdbdb",
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardItemText: {
    color: "#666",
    paddingHorizontal: 5,
    paddingVertical: 3,
  },
  panelItem: {
    overflow: "hidden",
    // borderRadius:8,
    margin: 10,
    elevation: 5,
  },
  panelItemTitle: {
    fontSize: 30,
    fontFamily: "monospace",
    fontWeight: "bold",
  },
  itemPressEffect: {
    minWidth: windowWidth - 20,
    flexDirection: "row",
    marginBottom: 10,
    flexWrap: "wrap",
    overflow: "hidden",
  },
  icon: {
    borderWidth: 0.2,
    //elevation: 4,
    borderColor: "#5c5c5c",
    opacity: 0.8,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
  },
});
