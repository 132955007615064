import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import {useEffect} from 'react'
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import {useTranslation} from 'react-i18next';
import './i18n'
import AsyncStorage from "@react-native-async-storage/async-storage" ;

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const {t, i18n} = useTranslation(); 
  const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };
    useEffect(() => {
      try {
       AsyncStorage.getItem('trans')
       .then((v) => {
         if(v) {
          changeLanguage(v)
         }else {
          changeLanguage('fr')
         }
       })
       
      } catch (e) {
        console.log(e)
      }
    }, [])
  if (!isLoadingComplete) {
    return null;
  } else {
  	
     return (
      <SafeAreaProvider>
        <Navigation colorScheme={colorScheme} />
        <StatusBar backgroundColor='#00ccff' style="light" />
      </SafeAreaProvider>
    );
  }
}
