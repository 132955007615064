import {StyleSheet, Pressable ,ActivityIndicator, Image } from 'react-native';
import { TextInput, Button } from 'react-native-paper';
import DropDownPicker from 'react-native-dropdown-picker';
import {useState, useEffect} from 'react'
import { Text, View } from '../components/Themed';
import Api from '../components/api/Api'
import Loading from '../components/ui/Loading'
import {FontAwesome} from '@expo/vector-icons';
import AsyncStorage from "@react-native-async-storage/async-storage" ;
import DateTimePicker from '@react-native-community/datetimepicker';
import CustomAlert from '../components/CustomAlert'
export default function NewRecordScreen({route, navigation}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState();
  const [items, setItems] = useState([]);
  const [isLoading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [recMember, setRecMember] = useState()
  const [recSum, setRecSum] = useState()
  const [error, setError] = useState([])
  const [success, setSuccess] = useState()
  const [newRecData, setNewRecData] = useState()
  const [date, setDate] = useState(new Date());
  const [mode, setMode] = useState('date');
  const [show, setShow] = useState(false);

  const onChange = (event, selectedDate) => {
    setShow(false);
    setDate(selectedDate);
  };

  const showMode = (currentMode) => {
    if (Platform.OS === 'android') {
      setShow(false);
      // for iOS, add a button that closes the picker
    }
    setMode(currentMode);
  };

  const showDatepicker = () => {
    showMode('date');
    setShow(true)
  };

  const filteredMember = (username, data) => {
   return data.filter((value) => {
      return value.username == username
    })
  }
 
  const recSaveHandler = async () => {
    if(!recMember) {
      setError({err_username: "Vous devez d'abord sélectionner un membre dans la liste"})
      return 
    } else if(isNaN(recSum) || recSum == 0 || !parseFloat(recSum)) {
       setError({err_sum: "Il semblait que vous avez oublié le montant par le membre"})
       return 
    } else {
      setSubmitLoading(true)
      const mb = filteredMember(recMember, newRecData)[0]
      const investorData = {
        username: mb.username, 
        full_name: mb.full_name,
        img: mb.img,
        amount: recSum,
        tel: mb.tel,
        time: date
      }
     await Api.postInvest(investorData)
     .then((insertedData) => {
       if(insertedData)
       setSubmitLoading(false)
       CustomAlert('New Contribution:', 'Inserted successfully.', [
         {text: 'Go to the contributions list', onPress: () => {
            setRecSum('')
            navigation.navigate('InvestorsDataScreen', {year: new Date(date).getFullYear()})
         }},
         {text: 'OK', onPress: () => {
            setRecSum('')
         }}
         ])
     })
     .catch((e) => {
       console.log(e)
      setLoading(false)
       })
    }
  }
  
  useEffect(() => {
    setItemsHandler()
  },[])
  
async function setItemsHandler() {
 if(route.params && route.params.data) {
   const data = route.params.data
         setNewRecData(data)
         const itemsFilter = data.map(({username, full_name, img}) => ({label: full_name, value: username, icon: () => <Image style={{width: 35, height: 35, borderRadius: 100}} source={{uri: img}}/>}))
         setItems(itemsFilter)
         setRecMember(itemsFilter.username)
         setLoading(false)
 } else { 
     await Api.fetchOrCache('members')
      .then((value) => {
      if(value) {
         setNewRecData(value)
         const itemsFilter = value.map(({username, full_name, img}) => ({label: full_name, value: username, icon: () => <Image style={{width: 35, height: 35, borderRadius: 100}} source={{uri: img}}/>}))
         setItems(itemsFilter)
         setLoading(false)
       }
   })
       .catch((e) => {
         console.log(e)
         setLoading(false)
   })
       .finally(() => {
         setLoading(false)
       })
    }
 }
 
  if (isLoading) {
    return <Loading />
  } else { 
    return (
    <View style={styles.container}>
    {success? <View style={{justifyContent: 'center', alignItems: 'center', backgroundColor: '#00ff9f'}}><Text style={{color: '#fff', fontWeight: 'bold'}}>{success}</Text></View> : ''}
    {error['err_username']? <Text style={{backgroundColor: '#e6e6e6', borderRadius: 5, padding:10, textAlign: 'center', color: '#ff0000'}}> {error['err_username']}</Text> : ''}
    {error['err_sum']? <Text style={{backgroundColor: '#e6e6e6', borderRadius: 5, padding:10, textAlign: 'center', color: '#ff0000'}}> {error['err_sum']}</Text> : ''}
    <View style={styles.inputContainer} >
    <DropDownPicker
     style={{marginBottom: 15, zIndex:1000 }}
     open={open}
     value={value}
     items={items}
     setOpen={setOpen}
     setValue={setValue}
     setItems={setItems}
     searchable={true}
     theme="DARK"
     listMode="MODAL"
     dropDownDirection="AUTO"
     loading={isLoading}
     searchPlaceholder="Recherche..."
     placeholder='Sélect un membre dans la liste:'
     dropDownContainerStyle={{ zIndex: 9000 }}
     selectedItemContainerStyle={{ backgroundColor: "#00ccff" }}
     listItemLabelStyle={{ color: "white" }}
     onSelectItem={(item) => {
     setRecMember(item.value)
     error['err_username'] = null
}}
   />
   <TextInput
    style={{marginBottom: 15}}
    mode='outlined'
    left={<TextInput.Icon size={25} color='#2b75ff9c' icon='cash-100'/>}
    label='Montant donné par personne'
    keyboardType='numeric'
    maxLength={6}
    value={recSum}
    returnKeyType='next'
    error={error['err_sum']? true : false}
    onChangeText={(text) => {
      setRecSum(text)
      setSuccess('')
      error['err_sum'] = null 
    }}
    />
   <TextInput
      style={{marginBottom: 15 }}
      mode='outlined'
      left={<TextInput.Icon size={25} color='#2b75ff9c' icon='clock'/>}
      label='Date'
      onPressIn={showDatepicker}
      value={date.toLocaleString()}
    />
      {show && (
        <DateTimePicker
          maximumDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
          minimumDate={new Date(2023, 0)}
          testID="dateTimePicker"
          value={date}
          mode={mode}
          is24Hour={true}
          onChange={onChange}
        />
      )}
    <Button onPress={() => {
    recSaveHandler()
    }} 
    icon='content-save'
    mode='contained'
    loading={submitLoading}
    >
     Enregistré
    </Button>
    </View>
<View>
    </View>
    </View>
  );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10
  },
  inputContainer: {
     paddingVertical: 30  
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
