import React, { useState, useEffect, createContext } from 'react'
import { isAdmin, getCurrentAdmin } from '../api/Api'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false)
  const [admin, setAdmin] = useState(null)
  
  async function authenticate () {
    isAdmin().then((status) => {
      setIsLogin(status)
      getAdmin()
    })
  }
  
  async function getAdmin () {
    getCurrentAdmin().then((currentAdmin) => {
      setAdmin(currentAdmin)
    })
  }
  
  return (
    <AuthContext.Provider value={{isLogin, authenticate, getAdmin, admin}} >
      {children}
    </AuthContext.Provider>
    )
}

export default AuthProvider
export {AuthContext}