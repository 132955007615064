import {StyleSheet} from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonContainer: {
    flexDirection: 'column',
    backgroundColor: '#00d0ff',
    borderRadius: 5,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 25,
    color: '#fff',
    width: 270,
    textAlign: 'center',
    fontWeight: 'bold'
  },
  title: {
    fontFamily: 'monospace',
    fontSize:20,
    paddingVertical: 10,
    borderBottomWidth:2,
    borderBottomColor: '#dbdbdb',
    width: '100%',
    textAlign: 'center',
  },
  year: {
      textAlign: 'center',
      backgroundColor: '#00d0ff',
      color: '#fff',
      fontSize:30, 
      fontFamily: 'monospace'
    } 
});

export default styles