import { useState , useEffect } from 'react'
import { View } from 'react-native'
import Panel from '../components/ui/panel/Panel'
import Loading from '../components/ui/Loading'
import {LinearGradient} from 'expo-linear-gradient';

export default function AdminScreen({route, navigation}) {
  const [isLoading, setLoading] = useState(true)
  
  useEffect(() => {
    setTimeout(() => {setLoading(false)}, 500)
  }, [])
  
  if(isLoading) {
   return <Loading />
  } else {
  const panelData = [
    {
      id: 1,
      description: {
        text: 'New Contribution',
        color: '#fff',
        size: 15,
        customStyle: {
          color: 'white',
          fontFamily: 'monospace'
        }
      },
      color: '#fff',
      background: '#fe1bb5',
      icon: {
       name: 'plus',
       size: 40,
       color: '#ffffff'
        },
      options: {
        onPress: () => navigation.navigate('NewRecordScreen')}
      }
   ]
    return (
        <View style={{flex:1}} >
           <Panel data={panelData}/>
        </View>
      )
  }
}