import {LinearGradient} from 'expo-linear-gradient';

const LinearGradientBackground = () => {
  return (
    <>
      <LinearGradient
        // Background Linear Gradient
        colors={['#00000070', 'transparent']}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          transform: [{rotate: '45deg'}], 
          height: '100%'
        }}
      />
    <LinearGradient
        // Background Linear Gradient
        colors={['#00000070', 'transparent']}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          transform: [{rotate: '-45deg'}], 
          height: '100%'
        }}
      />
    </>
    )
}

export default LinearGradientBackground