import { useEffect, useState } from 'react'
import { View, Text, FlatList, StyleSheet, ScrollView ,Image, TouchableOpacity} from 'react-native'
import styles from '../styles'
import {useTranslation} from 'react-i18next';
import '../i18n'

export default function TotalCashItem ({data}) {
  const [displayEvent, setDisplayEvent] = useState(false)
  const [totalMembers, setTotalMembers] = useState()
      const {t, i18n} = useTranslation(); 
    
   const monthName =  [
        'Init',
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Decembre',
      ]
   
   let sumAmount = 0;
   for (let i = 0; i < data.length; i++) {
    sumAmount += parseFloat(data[i].amount);
    }
    
  function toggleHandler() {
    setDisplayEvent(!displayEvent)
  }
  
  const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" }
  return new Date(dateString).toLocaleDateString(undefined, options)
}
  
  return (
 <View style={{flex: 1, padding: 10, alignItems: 'center', justifyContent: 'center'}}>
 <Text style={{marginBottom: 100, fontSize: 18, color: '#009103', fontFamily: 'monospace'}}>{t('totalcashtext')}</Text>
 <Text style={{ color: '#5c5c5c', textAlign: 'center', fontWeight : 'bold', fontSize:30}}>{sumAmount} FCFA</Text>
 </View>
  )
}

