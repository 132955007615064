import {Pressable, Button, ActivityIndicator, TouchableOpacity, Linking, Image, FlatList, StyleSheet ,View, Text} from "react-native";
import { useState , useEffect, useContext } from 'react'
import {FontAwesome} from '@expo/vector-icons';
import LoginForm from '../components/auth/LoginForm'
import URLHelper from '../components/URLHelper'
import { AuthContext } from '../components/auth/AuthProvider'
import { StackActions } from '@react-navigation/native';
import Loading from '../components/ui/Loading'
import FlatPanel from '../components/ui/panel/FlatPanel'
import CustomAlert from '../components/CustomAlert'
import AdminProfileCard from '../components/ui/panel/AdminProfileCard'
import AsyncStorage from "@react-native-async-storage/async-storage" ;
import {useTranslation} from 'react-i18next';
import '../i18n'

export default function LoginScreen({navigation}) {
  const {t, i18n} = useTranslation(); 
  const [isLoading, setLoading] = useState(true)
  const { isLogin, authenticate, admin } = useContext(AuthContext)
  
    useEffect(() => {
      authenticate().then(() => {
        setTimeout(() => {
        setLoading(false)
      }, 200)
      if(isLogin) {
        navigation.setOptions({
        headerRight: () => {
         return (<TouchableOpacity onPress={() => {}}><Image style={{borderRadius: 100, width: 30, height: 30}} source={{uri: admin.admin.img}}/></TouchableOpacity>)
        }
      })
      }
    }).catch((e) => setLoading(false))
  }, [])
  
  if(isLoading) {
    return <Loading />
  }  else {
   const panelData = [
     {
      id: 0,
      username: admin? admin.admin.username: 'Unknown username',
      userFullName: admin? admin.admin.full_name : 'Unknown Full name',
      tel: admin? admin.admin.tel : 'Unknown tel',
      time: admin? admin.admin.time : 'Unknown date',
      onPress: () => CustomAlert('Log out?', 'Are you sure you want to log out?', [{text: 'Yes', style: 'ok',  onPress: () => {
          AsyncStorage.removeItem('admin').then(() => {
            authenticate(false).then(() => {
              navigation.replace('FeatHomeScreen', {message: 'Log out!'})
            })
          })
        }}, {text: 'Cancel', style: 'cancel'}]),
      description: {
        text: t('admin.dashboard')
      },
      color: '#fff',
      background: '#fff',
      icon: {
        uri: admin? admin.admin.img : ''
        }
    },
    ]
    return (
    <View style={styles.container}>
    {isLogin? 
      (<View style={{flex:1}}>
      <AdminProfileCard data={panelData} />
      <View style={{backgroundColor: 'white', borderRadius: 5, marginTop: 5, padding: 5, marginHorizontal: 5}}>
      <TouchableOpacity
      onPress={() => navigation.replace('AdminPanelScreen')}
      >
      <Text 
      style={{elevation: 5, padding: 10, fontWeight: 'bold', color: '#5c5c5c', backgroundColor: 'white'}}>{t('admin.dashboard')}</Text>
      </TouchableOpacity>
      <TouchableOpacity
      onPress={() => navigation.navigate('SettingScreen')}
      >
      <Text style={{elevation: 5, padding: 10, fontWeight: 'bold', color: '#5c5c5c', backgroundColor: 'white'}}>{t('setting.title')}</Text>
      </TouchableOpacity>
      </View>
      </View>)
    : (<LoginForm navigation={navigation}/>)
    }
    </View>
  )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgb(208,208,208)'
  },
  title: {
    fontFamily: "space-mono",
    fontSize: 30,
    textAlign: "center",
    color: "#00ccff",
    borderBottomWidth: 0.3,
    borderBottomColor: "#989898",
    marginTop: 20,
  },
    adminPanel: {
    padding: 7,
    flexDirection: 'row',
    borderBottomColor: '#dbdbdb',
    borderBottomWidth: 4,
    alignItems: 'center',
    backgroundColor:'rgb(232,232,232)',
    justifyContent: 'space-evenly'
  },
  profilePc: {
    width: 135,
    height: 135,
    borderRadius: 50,
    marginRight:10
  },
  p: {
    fontSize: 16,
    color: 'rgb(94,94,94)' 
  },
  controlPanel: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'rgb(28,0,0)',
    alignItems: 'center'
  },
  control: {
    padding :5,
    margin: 5
  } 
});