import {
  Dimensions,
  StyleSheet,
  Pressable,
  ImageBackground,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
  FlatList,
} from "react-native";
import { useState, useEffect, useContext } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Text, View } from "../../Themed";
import { FontAwesome } from "@expo/vector-icons";
import DataItem from "../../DataItem";
import { LinearGradient } from "expo-linear-gradient";
import { useTranslation } from "react-i18next";
import AuthProvider, { AuthContext } from "../../auth/AuthProvider";

export default function Panel({ data }) {
  const AuthCtx = useContext(AuthContext);

  useEffect(() => {
    AuthCtx.authenticate();
  }, []);

  return (
    <View style={styles.panel}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        {data.map((item) => (
          <ImageBackground
            imageStyle={{ opacity: 0.9 }}
            source={{
              uri: "https://firebasestorage.googleapis.com/v0/b/f-walewal-5d67b.appspot.com/o/panel-item-background.png?alt=media&token=5e343bba-76ce-4ed5-8c5e-2e2c6f47c8b9",
            }}
            key={item.id}
            style={styles.panelItem}
          >
            <Pressable
              onPress={() => {
                item.options.onPress();
              }}
              android_ripple={{ color: item.color ? item.color : "#fff" }}
              style={[
                styles.itemPressEffect,
                {
                  justifyContent:
                    item.icon && item.icon.description
                      ? "center"
                      : "space-evenly",
                  backgroundColor: "transparent",
                },
              ]}
            >
              <LinearGradient
                // Background Linear Gradient
                colors={[
                  item.background ? item.background : "#fff",
                  "transparent",
                ]}
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  transform: [{ scale: 3 }],
                  height: "85%",
                }}
              />
              {item.icon ? (
                <View
                  style={[
                    styles.icon,
                    {
                      backgroundColor: item.background
                        ? item.background
                        : "e300ff99",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.panelItemTitle,
                      { color: item.color ? item.color : "#fff" },
                    ]}
                  >
                    <FontAwesome
                      size={item.icon.size}
                      color={item.icon.color}
                      name={item.icon.name}
                    />
                  </Text>
                </View>
              ) : (
                ""
              )}
              {item.description ? (
                <Text
                  style={[
                    { color: "#fff", padding: 6 },
                    item.description.customStyle,
                  ]}
                >
                  {item.description.text}
                </Text>
              ) : (
                ""
              )}
            </Pressable>
          </ImageBackground>
        ))}
      </ScrollView>
    </View>
  );
}

const windowWidth = Dimensions.get("window").width;

const styles = StyleSheet.create({
  panel: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "transparent",
  },
  panelItem: {
    overflow: "hidden",
    borderRadius: 8,
    margin: 10,
    elevation: 4,
  },
  panelItemTitle: {
    fontSize: 30,
    fontFamily: "monospace",
    fontWeight: "bold",
  },
  itemPressEffect: {
    alignItems: "center",
    // elevation: 4,
    // borderRadius: 8,
    width: (windowWidth - 20) / 2.3,
    height: (windowWidth - 20) / 2.3,
  },
  icon: {
    borderWidth: 0.2,
    elevation: 4,
    borderColor: "#5c5c5c",
    width: 60,
    height: 60,
    opacity: 0.8,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
  },
});
