import { StyleSheet, ActivityIndicator, TouchableOpacity, ScrollView, FlatList } from 'react-native';
import { useState, useEffect } from 'react'
import { Text, View } from '../components/Themed';
import styles from './styles'
import NetInfo from "@react-native-community/netinfo";
import Axios from 'axios'
import MembersItem from '../components/MembersItem'
import AsyncStorage from "@react-native-async-storage/async-storage" ;
import URLHelper from '../components/URLHelper'
import { useTranslation } from 'react-i18next';
import '../i18n'
import Api from '../components/api/Api'
import Loading from '../components/ui/Loading'

export default function MembersScreen({navigation}) {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState()
  const [netStatus, setNetStatus] = useState(false)
  const {t, i18n} = useTranslation(); 
  
  const fetchData = async () => {
     await Api.fetchOrCache('members').then((data) => {
     if(data) {
       setData(data)
       setLoading(false)
       }
     }).catch((e) => {
       console.log(e)
     })
   } 
   
   useEffect(() => {
    setTimeout(() => {setLoading(false)}, 2000)
    fetchData()
   },[])

  if(isLoading){
  return <Loading />
  } else if(data) {
   return (
    <View style={{flex: 1}}>
      <MembersItem data={data} />
    </View>
    )
  } else {
    return (
      <View style={{flex:1, justifyContent: 'center', alignItems: 'center', padding: 10}}>
      <Text style={{fontSize: 17,fontFamily: 'monospace',color: '#fd7343'}}>{t('errnet')}</Text>
      </View>
     )
  }
}

