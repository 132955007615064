import {Pressable, StyleSheet, ActivityIndicator, TouchableOpacity, ScrollView, FlatList } from 'react-native';
import { useState, useEffect } from 'react'
import {FontAwesome} from '@expo/vector-icons';
import Ionicons from '@expo/vector-icons/Ionicons';
import AsyncStorage from "@react-native-async-storage/async-storage" ;
import { Text, View } from '../components/Themed';
import styles from './styles'
import DataItem from '../components/DataItem'
import URLHelper from '../components/URLHelper'
import CustomAlert from '../components/CustomAlert'
import Api from '../components/api/Api'
import {useTranslation} from 'react-i18next';
import Loading from '../components/ui/Loading'
import NetInfo from "@react-native-community/netinfo";

export default function InvestorsDataScreen({route, navigation}) {
 const {t, i18n} = useTranslation(); 
 const [error, setError] = useState()
 const [data, setData] = useState()
 const [isLoading, setLoading] = useState(true)
 const [loadingMessage, setLoadingMessage] = useState(t('loading.simple'))
 const [months, setMonths] = useState([])
 const { year } = route.params
    
 const refreshData = () => {
   Api.fetch('investors')
   .then((response) => {
     setLoading(true)
     setLoadingMessage('Refreshing...')
     if(response) {
       const currentTime = new Date().getTime();
          const updatedData = {
            data: response,
            timestamp: currentTime
          };
          try {
          AsyncStorage.setItem('investors', JSON.stringify(updatedData))
            .then(() => {
              setLoading(false)
              CustomAlert('Success', 'Refreshed successfully!', [
                {text: 'Reload', onPress: () => navigation.replace('InvestorsDataScreen', {year: year})}
                ])
            })
          } catch (e) {
            console.log(e)
            setLoading(false)
            CustomAlert('Technical Error', 'Something went wrong please try again later.')
          }
     }
     else {
       setLoading(false)
       CustomAlert('Error network:', 'Operation failed! Please check your network and try again.')
    }
   })
   .catch((e) => {
     console.log(e)
     setLoading(false)
   })
 }
 
 const fetchData = async () => {
    await Api.fetchOrCache('investors')
     .then((value) => {
     if(value && year) {
      const filteredData = Array.isArray(value) && value?.filter(row => {
      const  vt = new Date(row.time);
      return vt.getUTCFullYear() === year;
    });
    if(filteredData) {
 let mnts = []
 for (let dates of filteredData) {
       mnts.push(dates.time)
   }
const allMonths = Array.from({ length: 12 }, (_, i) => i + 1);
const result = allMonths.filter(month => {
  return mnts.some(dateString => {
    const date = new Date(dateString);
    return date.getUTCMonth() + 1 === month;
  });
});
    setMonths(result);
    setData(filteredData)
    setLoading(false)
    }
     }
     })
     .catch((e) => {
       console.log(e)
       setLoading(false)
       })
     .finally(() => {
       setLoading(false)
     })
   } 
 useEffect(() => {
   fetchData()
   navigation.setOptions({
     headerRight: () => (
      <View style={ { flexDirection: 'row', backgroundColor : 'transparent'}}>
    <Pressable
        onPress={() => refreshData()}
        style={({ pressed }) => ({
          opacity: pressed ? 0.5: 1,
        })}
    >
      <Ionicons
          name="refresh-circle"
          size={25}
          color='#fff'
          style={ { marginRight: 15 }}
          />
    </Pressable>
    <Pressable
        onPress={() => navigation.navigate('LoginScreen')}
        style={({ pressed }) => ({
          opacity: pressed ? 0.5: 1,
        })}>
      <FontAwesome
          name="user"
          size={25}
          color='#fff'
          style={ { marginRight: 15 }}
          />
    </Pressable>
    <Pressable
        onPress={() => navigation.navigate('SettingScreen')}
        style={({ pressed }) => ({
          opacity: pressed ? 0.5: 1,
        })}>
      <FontAwesome
          name="cog"
          size={25}
          color='#fff'
          />
    </Pressable>
    </View>
    ), 
   })
 }, [])
 if(isLoading) {
   return <Loading message={loadingMessage}/>
 } else if (data && year) {
   return (
     <View style={[styles.container, {backgroundColor: '#ececec'}]}>
    <Text style={styles.year}>{year}</Text>
    {year > 2022?
    <>
    <DataItem data={data} year={year} month={1}/>
    <DataItem data={data} year={year} month={2}/>
    <DataItem data={data} year={year} month={3}/>
    <DataItem data={data} year={year} month={4}/>
    <DataItem data={data} year={year} month={5}/>
    <DataItem data={data} year={year} month={6}/>
    <DataItem data={data} year={year} month={7}/>
    <DataItem data={data} year={year} month={8}/>
    <DataItem data={data} year={year} month={9}/>
    <DataItem data={data} year={year} month={10}/>
    <DataItem data={data} year={year} month={11}/>
    <DataItem data={data} year={year} month={12}/>
    </>
    : <DataItem data={data} year={2022} month={12}/>
    }
    </View >
      )
   
 } 
 else {
     return (
      <View style={{flex:1, justifyContent: 'center', alignItems: 'center', padding: 10}}>
      <Text style={{fontSize: 17,fontFamily: 'monospace',color: '#fd7343'}}>{t('errnet')}</Text>
      </View>
     )
 }
}

