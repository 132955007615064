/**
* If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
* https://reactnavigation.org/docs/getting-started
*
*/
import {FontAwesome} from '@expo/vector-icons';
import Ionicons from '@expo/vector-icons/Ionicons';
import {
  createBottomTabNavigator
} from '@react-navigation/bottom-tabs';
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  useNavigation
} from '@react-navigation/native';
import {
  createNativeStackNavigator
} from '@react-navigation/native-stack';
import 'react-native-gesture-handler';
import * as React from 'react';
import {Image, ColorSchemeName, Pressable,Text,View,ActivityIndicator,Alert} from 'react-native';
import {useTranslation} from 'react-i18next';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import AboutScreen from '../screens/AboutScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import Data2022Screen from '../screens/Data2022Screen';
import Data2023Screen  from '../screens/Data2023Screen';
import HomeScreen from '../screens/HomeScreen';
import FeatHomeScreen from '../screens/FeatHomeScreen';
import LoginScreen from '../screens/LoginScreen';
import AdminScreen from '../screens/AdminScreen';
import MembersScreen from '../screens/MembersScreen';
import CalendarScreen from '../screens/CalendarScreen';
import TotalCashScreen from '../screens/TotalCashScreen';
import NewRecordScreen from '../screens/NewRecordScreen';
import AddNewMemberScreen from '../screens/AddNewMemberScreen';
import InvestorsDataScreen from '../screens/InvestorsDataScreen';
import SettingScreen from '../screens/SettingScreen';
import CustomAlert from '../components/CustomAlert'

import {
  RootStackParamList,
  RootTabParamList,
  RootTabScreenProps
} from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import {RefreshData} from '../components/api/Api'
import NetworkStatusOnRefresh from '../components/NetworkStatus'
import NetInfo from "@react-native-community/netinfo";
import URLHelper from '../components/URLHelper'
import { useState, useEffect, useContext } from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer';
import AsyncStorage from "@react-native-async-storage/async-storage" ;
import AuthProvider, { AuthContext } from '../components/auth/AuthProvider'

export default function Navigation({
  colorScheme
}: {
  colorScheme: ColorSchemeName
}) {
  
  return (
    <AuthProvider>
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme: DefaultTheme}>
      <RootNavigator />
      </NavigationContainer>
    </AuthProvider>
  );
}

const Drawer = createDrawerNavigator()

function DrawerNavigator () {
  const colorScheme = useColorScheme(); 
  const {t, i18n} = useTranslation(); 
    
  return (
    <Drawer.Navigator
    initialRouteName='DrawerHome'
    screenOptions={{
    tabBarActiveTintColor: Colors[colorScheme].tint,
    headerStyle: {
    backgroundColor: '#00ccff',
    },
    drawerLabelStyle: {color: '#fff'},
    headerTintColor: 'white',
    drawerContentStyle: {backgroundColor: '#00ccff'}
    }}
    >
       <Stack.Screen
          name="DrawerHome"
          component={FeatHomeScreen}
          options={({ navigation }: RootTabScreenProps < 'HomeScreen' >) => ({
            title: t('home.title'),
            drawerIcon: ({ color }) => <FontAwesome
              name="home"
              size={25}
              color='#fff'
              />,
            headerRight: () => (
              <View style={ { flexDirection: 'row' }}>
            <Pressable
                onPress={() => navigation.navigate('LoginScreen')}
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5: 1,
                })}>
              <FontAwesome
                  name="user"
                  size={25}
                  color='#fff'
                  style={ { marginRight: 15 }}
                  />
            </Pressable>
            <Pressable
                onPress={() => navigation.navigate('SettingScreen')}
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5: 1,
                })}>
              <FontAwesome
                  name="cog"
                  size={25}
                  color='#fff'
                  style={ { marginRight: 15 }}
                  />
            </Pressable>
            </View>
            ),
          })}
          />
   
    </Drawer.Navigator>
    )
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const AuthStack = createNativeStackNavigator<RootStackParamList>();

function AuthStackNavigator () {
     const colorScheme = useColorScheme();
    const {t, i18n} = useTranslation(); 
    const navigation = useNavigation()
    const { isLogin, authenticate, admin } = useContext(AuthContext)
    
return (
    <AuthStack.Navigator 
    initialRouteName='AdminScreen'
    screenOptions={{
       headerTintColor: 'white',
       headerStyle: {backgroundColor: '#00ccff'},
       headerRight: () => (
              <View style={{ flexDirection: 'row' }}>
            <Pressable
                onPress={() => navigation.navigate('LoginScreen')}
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5: 1,
                })}>
       
              {admin?
              <Image source={{uri: admin.admin.img}} style={{borderRadius: 100, marginRight: 15, width: 25, height: 25}} />
              : 
              <FontAwesome
                  name="user"
                  size={25}
                  color='#fff'
                  style={ { marginRight: 15 }}
                  />
              }
            </Pressable>
            <Pressable
                onPress={() => navigation.navigate('SettingScreen')}
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5: 1,
                })}>
              <FontAwesome
                  name="cog"
                  size={25}
                  color='#fff'
                  style={ { marginRight: 15 }}
                  />
            </Pressable>
                       <Pressable
               onPress={() => {
                CustomAlert('Log out', 'Are you sure you want to log out?', [
                {text: 'Yes', style: 'ok', onPress: () => {
                     AsyncStorage.removeItem('admin')
                    navigation.replace('FeatHomeScreen', {unlinkAdmin: true})
                }},
                {text: 'Cancel', style: 'cancel'}
                ])
               }}
               style={({ pressed }) => ({
                 opacity: pressed ? 0.5: 1,
               })}>
               <FontAwesome color='#ff4300' size={25} name='sign-out'/>
           </Pressable>
            </View>
            ),
    }}
    >
        <AuthStack.Screen name="NewRecordScreen" component={NewRecordScreen}  options={{
          title: 'Add Record'
        }} />
        <AuthStack.Screen name="AddNewMemberScreen" component={AddNewMemberScreen} options={{
                title: 'Add Member',
            }} />
        <AuthStack.Screen name="AdminScreen" component={AdminScreen} options={ {
            title: 'Admin',
            
          }}/>
    </AuthStack.Navigator>
    )
}

const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
    const colorScheme = useColorScheme();
    const {t, i18n} = useTranslation(); 
    const navigation = useNavigation()
    const { isLogin, authenticate } = useContext(AuthContext)
  return (
    <Stack.Navigator
      initialRouteName='FeatHomeScreen'
      screenOptions={{
        //headerTransparent: true,
        headerTintColor: 'white', 
        headerTitleStyle: {color: '#fff'},
        headerShown: false,
        statusBarAnimation: 'fade',
        headerRight: () => (
              <View style={ { flexDirection: 'row' }}>
            <Pressable
                onPress={() => navigation.navigate('LoginScreen')}
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5: 1,
                })}>
              <FontAwesome
                  name="user"
                  size={25}
                  color='#fff'
                  style={ { marginRight: 15 }}
                  />
            </Pressable>
            <Pressable
                onPress={() => navigation.navigate('SettingScreen')}
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5: 1,
                })}>
              <FontAwesome
                  name="cog"
                  size={25}
                  color='#fff'
                  />
            </Pressable>
            </View>
            ),
        headerStyle: {backgroundColor: '#00ccff'}
      }}> 
      
      <Stack.Screen name="FeatHomeScreen" component={DrawerNavigator} options={({ navigation }: RootTabScreenProps < 'HomeScreen' >) => ({
            title: 'Home',
          })}/>
     {isLogin &&
      <Stack.Screen name='AdminPanelScreen' component={AuthStackNavigator} options={({ navigation }: RootTabScreenProps < 'HomeScreen' >) => ({
            title: 'Home',
            headerRight: () => (
              <View style={ { flexDirection: 'row' }}>
            <Pressable
                onPress={() => navigation.navigate('AdminScreen')}
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5: 1,
                })}>
              <FontAwesome
                  name="user"
                  size={25}
                  color='#fff'
                  style={ { marginRight: 15 }}
                  />
            </Pressable>
            <Pressable
                onPress={() => navigation.navigate('SettingScreen')}
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5: 1,
                })}>
              <FontAwesome
                  name="cog"
                  size={25}
                  color='#fff'
                  style={ { marginRight: 15 }}
                  />
            </Pressable>
            </View>
            ),
          })}/>
     }
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Screen name="LoginScreen" component={LoginScreen} options={{
        title: isLogin? t('admin.dashboard') : t('login.title'),
        headerShown: true 
      }}/>
      <Stack.Screen name="MembersScreen" component={MembersScreen} options={ {
            title: t('members'),
            headerShown: true,
          }}/>
      <Stack.Screen name="InvestorsDataScreen" component={InvestorsDataScreen} options={{
            title: 'Contribution',
            headerShown: true,
            }} />
         <AuthStack.Screen name="NewRecordScreen" component={NewRecordScreen}  options={{
          headerShown: true,
          title: 'Add Record'
        }} />
      <Stack.Screen name="TotalCashScreen" component={TotalCashScreen} options={ {
            title: t('total.title'),
            headerShown: true,
          }}/>
      <Stack.Screen name="SettingScreen" component={SettingScreen} options={{
        title: t('setting.title'),
        headerShown: true,
        }}/>
      <Stack.Screen name="CalendarScreen" component={CalendarScreen} options={{
           title: 'Calendar',
           headerShown: true,
        }}/>
      <Stack.Screen name="AboutScreen" component={AboutScreen} options={{
           title: t('about.title'),
           headerShown: true,
        }}/>
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */


  /**
  * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
  */
  function TabBarIcon(props: {
    name: React.ComponentProps < typeof FontAwesome > ['name'];
    color: string;
  }) {
    return <FontAwesome size={25} style={ { marginBottom: -3 }} {...props} />;
  }