import {ActivityIndicator, Alert, Pressable, Image, StyleSheet, View, Text} from "react-native";
import {TextInput, Button} from 'react-native-paper'
import { useState , useEffect, useContext } from 'react'
import { FetchAdmin } from '../helper/Functions'
import {getDataCache} from '../Cache'
import AsyncStorage from '@react-native-async-storage/async-storage';
import {GetAdmin} from '../components/api/Api'
import NetInfo from "@react-native-community/netinfo";
import Axios from 'axios'
import LinearGradientBackground from '../ui/LinearGradientBackground'
import Ionicons from '@expo/vector-icons/Ionicons';
import URLHelper from '../URLHelper'
import { AuthContext } from './AuthProvider'

export default function LoginForm({navigation}) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [valueErr, setValueErr] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [isSubmittedLoading, setIsSubmittedLoading] = useState (false)
  const [showPassword, setShowPassword] = useState(true)
  const authCtx = useContext(AuthContext)

function nameInputHandler(text) {
  setUsername(text)
  setValueErr([])
}
const showPasswordHandler = () => {
  setShowPassword(showPassword? false : true)
}
function passwordInputHandler(text) {
  setPassword(text)
  setValueErr([])
}

async function onLoggingHandler() {
   if(username.length < 3) {
     setValueErr({err_username:  "Vôtre nom n'est devrait pas être moins de [3] lettres."})
   } else if(!password) {
     setValueErr({err_password:  "Le champ de mot de passe n'est peut pas être vide."})
   } else {
     NetInfo.fetch().then((state) => {
       if(state.isConnected && state.isInternetReachable) {
         try{
          setLoading(true)
          setIsSubmittedLoading(true)
          Axios.post(URLHelper.admin, {username: username.trim(), password: password.trim()})
          .then(response => {
            if(response.data.message){
              setValueErr({err_auth: response.data.message})
              setIsSubmittedLoading(false)
            } else {
               AsyncStorage.setItem('admin', JSON.stringify(response.data));
                 setIsSubmittedLoading(false)
                 authCtx.authenticate(true).then(() => {
                 navigation.replace('FeatHomeScreen', {message: 'Login successfully!'})
                 setLoading(false)
                 })
            }
          })
         } catch(e) {
           console.log(e)
           setIsSubmittedLoading(false)
           setValueErr({err_network: "Une erreur c'est produire veillez vérifiez  votre connexion internet et ressayer s'il vous plaît."})
         } finally {
           setLoading(false)
         }
       } else {
         Alert.alert('Error network:', 'Operation failed! Please check your network and try again.')
       }
 }) 
} }
 
function MButton() {
  return (
    <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button 
            mode="contained"
            buttonColor='#00ccff'
            loading={isSubmittedLoading}
            onPress={onLoggingHandler}
            >
              Login
            </Button>
          </View>
    </View>
    )
}

  return (
    <View style={styles.formContainer}>
    <LinearGradientBackground />
    <View style={styles.form}>
    <View style={styles.userIconTitle}>
      <Ionicons name="person-circle-outline" size={60} color="#777" /> 
    </View>
      {valueErr['err_network']? <Text style={styles.err}>{valueErr['err_network']}</Text> : ''}
       {valueErr['err_auth']? <Text style={styles.err}>{valueErr['err_auth']}</Text> : ''}
       <View style={styles.inputContainer}>
       <View style={styles.inputItemContainer}>
        <TextInput
        error={valueErr['err_username']? true : false}
        textContentType='name' 
        autoFocus={true}
        value={username}
        label='Name:'
        mode='outlined'
        style={styles.inputItem}
        onChangeText={nameInputHandler} 
        right={<TextInput.Icon icon="account" />}
    
       />
       </View>
       <View style={styles.inputItemContainer}>
        <TextInput 
        textContentType='password' 
        right={
        <TextInput.Icon icon="eye" 
        onPress={showPasswordHandler}
        />}
        mode='outlined'
        secureTextEntry={showPassword}
        value={password} 
        label='Password:'
        style={styles.inputItem} 
        error={valueErr['err_password']? true : false}
        onChangeText={passwordInputHandler}/>
       </View>
       <MButton />
       </View>
   </View>
   </View>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    width: '100%',
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent'
    
  },
  inputItemContainer: {
    elevation: 10,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'transparent'
  },
    title: {
      width: '100%',
    fontFamily: "space-mono",
    fontSize: 20,
    textAlign: "center",
    color: "#00ccff",
    borderBottomWidth: 0.3,
    borderBottomColor: "#989898",
  },
  inputItem: {
    color: '#1d1d1d',
    width: '100%',
    marginBottom: 15,
  },
  userIconTitle: {
    backgroundColor: '#00d0ff90',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
   
  },
  formContainer: {
    flex:1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 15,
    backgroundColor: 'transparent',
    overflow: 'hidden'
  },
  form: {
    width: '100%',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingBottom: 40,
    elevation: 2,
    borderRadius: 5,
    opacity: 0.8
  },
  buttonContainer: {
    flexDirection: "row",
    marginTop:10
  },
  button: {
    width: "100%",
    marginHorizontal: 10,
    fontSize: 17
  },
  err: {
    color: '#ff0000',
    fontSize: 17
  },
  success: {
    color: '#15ff00',
    fontSize: 17
  }
});