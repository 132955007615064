import { useEffect, useState } from 'react'
import { View, Text, FlatList, StyleSheet, Linking, ScrollView ,Image, TouchableOpacity} from 'react-native'
import styles from '../styles'
import {FontAwesome} from '@expo/vector-icons';
import {useTranslation} from 'react-i18next';
import '../i18n'
import MembersProfileCard from './ui/panel/MembersProfileCard'
export default function MembersItem ({data}) {
  const [displayEvent, setDisplayEvent] = useState(false)
  const [totalMembers, setTotalMembers] = useState()
  const {t, i18n} = useTranslation(); 

   const monthName =  [
        'Init',        
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december')
      ]
   
   let sumAmount = 0;
   for (let i = 0; i < data.length; i++) {
    sumAmount += parseFloat(data[i].amount);
    }
   let sumMembers = 0;
   for (let i = 0; i < data.length; i++) {
    sumMembers += parseFloat(data[i].id);
    }
    
  function toggleHandler() {
    setDisplayEvent(!displayEvent)
  }
  
  const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" }
  return new Date(dateString).toLocaleDateString(undefined, options)
}
  
  return (
 <View style={{flex: 1}}>
 <View style={{alignItems: 'center',justifyContent : 'center', padding: 10, borderBottomWidth: 2, borderBottomColor : '#bebebe'}}>
 <Text style={{color: '#00ccff', fontWeight : 'bold', fontSize:30}}>{t('totalmembers')}: {Math.max(data.length)}</Text>
 </View>
  <MembersProfileCard data={data}/>
  </View>
  )
}

