import {StyleSheet, ActivityIndicator, TouchableOpacity, ScrollView, FlatList } from 'react-native';
import {Button} from 'react-native-paper'
import { useState, useEffect } from 'react'
import { Text, View } from '../components/Themed';
import NetInfo from "@react-native-community/netinfo";
import Axios from 'axios'
import MembersItem from '../components/MembersItem'
import AsyncStorage from "@react-native-async-storage/async-storage" ;
import URLHelper from '../components/URLHelper'
import Loading from '../components/ui/Loading'
import {useTranslation} from 'react-i18next';
import '../i18n'
import CustomAlert from '../components/CustomAlert'
import DropDownPicker from 'react-native-dropdown-picker';

export default function SettingScreen({navigation}) {
    const {t, i18n} = useTranslation(); 
    const [cacheValue, setCacheValue] = useState('English')
    const [isLoading, setLoading] = useState(true)
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [items, setItems] = useState([
    {label: 'Français', value: 'fr'},
    {label: 'English', value: 'en'},
    {label: 'عربي', value: 'ar'}
  ]);
  const getLang = async ()  => {
    try {
      await AsyncStorage.getItem('trans')
      .then((cValue) => {
        if(cValue) {
          setCacheValue(cValue)
          setLoading(false)
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        })
      .finally(() => {setLoading(false)})
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
    
  }
  useEffect(() => {
    getLang()
  }, [])
if(isLoading) {
  return (
    <Loading />
    ) 
   } else {
     return(
  <View style={styles.container}>
  <View style={styles.selectContainer}>
  <Text style={styles.ItemTitle}>{t('setting.languages')}</Text>
    <DropDownPicker
      style={{borderWidth: 0, backgroundColor: "rgb(243,243,243)"}}
      open={open}
      value={value}
      placeholder={cacheValue? (cacheValue == 'fr' ? 'Français' : (cacheValue == 'en'? 'English' : 'عربي')) : 'Français'}
      items={items}
      setOpen={setOpen}
      setValue={setValue}
      setItems={setItems}
      zIndex={1000}
      textStyle={{
        fontSize: 14,
        color: '#5c5c5c'
      }}
      onSelectItem={(item) => {
        setCacheValue(item.value);
      }}
    />
    </View>
     <View style={styles.buttonContainer}>
     <Button
      icon='content-save-edit'
      mode='contained'
      disabled={value? false : true}
      onPress={() => {
        changeLanguage(value)
        AsyncStorage.setItem('trans', value)
        .then(() => { 
          CustomAlert(t('setting.title'), t('setting.saved-alert'), [
            {text: t('buttons.go-home'), onPress: () => navigation.navigate('FeatHomeScreen')},
            {text: t('buttons.ok'), onPress: () => setValue(null)}
          ])
        })
        .catch((e) => console.log(e))
      }}
      >
      {t('buttons.save')}
      </Button>
      <Button
      buttonColor='#ff6815'
      icon='cancel'
      mode='contained'
      onPress={() => navigation.navigate('FeatHomeScreen')}
      >
       {t('buttons.cancel')} 
      </Button>
      
     </View>
  </View>
  );
}}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 15,
    
  },
  selectContainer: {
    zIndex: 100 
  },
  ItemTitle: {
    fontFamily: 'monospace',
    marginBottom: 10 
  },
  buttonContainer: {
    marginTop: 100,
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  }
})