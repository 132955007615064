import { View, ActivityIndicator, Text, StyleSheet } from 'react-native'
import {useTranslation} from 'react-i18next';
import '../../i18n'

export default function Loading ({message}) {
  const {t, i18n} = useTranslation(); 
    
  return (
    <View style={styles.container}>
      <View style={styles.loadingBox}>
      <ActivityIndicator size='large'/>
        <Text>{message? message : t('loading.simple')}</Text>
      </View>
    </View>
    )
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#00000090',
    zIndex:  1000 ,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  loadingBox: {
    width: 150,
    height: 150,
    elevation: 5,
    borderRadius: 10,
    overflow: 'hidden',
   backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center'
  }
})