import { StyleSheet, Pressable ,ActivityIndicator, Image } from 'react-native';
import { TextInput, Button } from 'react-native-paper';
import DropDownPicker from 'react-native-dropdown-picker';
import {useState, useEffect} from 'react'
import { Text, View } from '../components/Themed';
import Api from '../components/api/Api'
import {FontAwesome} from '@expo/vector-icons';
import AsyncStorage from "@react-native-async-storage/async-storage" ;

export default function AddNewMemberScreen({route, navigation}) {

  // TODO
}
  const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10
  },
  inputContainer: {
     paddingVertical: 30  
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
