import { Image, Linking, StyleSheet } from "react-native";
import { Text, View } from "../components/Themed";
import {useTranslation} from 'react-i18next';
import { useState, useEffect } from 'react'
import '../i18n'
export default function AboutScreen({navigation}) {
  const {t, i18n} = useTranslation(); 

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{t('appname')}</Text>
      <View style={styles.imageContainer}>
        <Image
          style={{ width: 260, height: 260, marginTop: 10}}
          source={require("../assets/images/icon.png")}
        />
      </View>
      <Text style={styles.text}>
      {t('about.p1')}
      </Text>
      <Text style={styles.text}>{t('about.p2')}<Text style={{color: 'blue'}}onPress={() => Linking.openURL('https://fw.abassdev.com')}> fw.abassdev.com </Text></Text>
      <View style={styles.footerContainer}>
      <Text style={styles.text}>{t('about.p3')}</Text>
        <Text style={styles.text}>{t('about.p4')}<Text style={{color: 'blue'}} onPress={() => Linking.openURL('https://abassdev.com')} >abassdev.com</Text> | 2022 - 2023</Text></View>
    </View>
  );
}

const styles = StyleSheet.create({
  footerContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
},
  container: {
    flex: 1,
  },
  title: {
    fontFamily: "space-mono",
    fontSize: 30,
    textAlign: "center",
    color: "#00ccff",
    borderBottomWidth: 0.3,
    borderBottomColor: "#989898"
  },
  text: {
    textAlign: "center",
    fontFamily: 'monospace',
    margin: 5
  },
  imageContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 17,
  },
});
