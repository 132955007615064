import i18n from 'i18next'; 
import {initReactI18next} from 'react-i18next'; 

import en from './locales/en.json'; 
import fr from './locales/fr.json'; 
import ar from './locales/ar.json'; 

i18n.use(initReactI18next).init({ 
  compatibilityJSON: 'v3',
  lng: 'fr', 
  fallbackLng: 'en', 

  resources: { 
    en: en, 
    fr: fr, 
    ar: ar, 
  }, 

  interpolation: { 
    escapeValue: false // react already safes from xss 
  },
  react: {
    useSuspense:false,
 }
}); 


export default i18n; 