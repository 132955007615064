import { ImageBackground } from 'react-native';
import { useState, useEffect } from 'react'
import AsyncStorage from "@react-native-async-storage/async-storage" ;
import {Dimensions} from 'react-native'
import DataItem from '../components/DataItem'
import URLHelper from '../components/URLHelper'
import Api from '../components/api/Api'
import {useTranslation} from 'react-i18next';
import Panel from '../components/ui/panel/Panel'
import {LinearGradient} from 'expo-linear-gradient';
import { useHeaderHeight } from '@react-navigation/elements';
import Loading from '../components/ui/Loading'
import CustomAlert from '../components/CustomAlert'

export default function FeatHomeScreen({navigation, route}) {
  const [isLoading, setLoading] = useState(true)
  const {t} = useTranslation()
  const panelData = [
    {
      id: 0,
      description: {
        text: (new Date().getFullYear())
      },
      color: '#fff',
      background: 'rgb(225,41,0)',
      icon: {
       name: 'tachometer',
       size: 40,
       color: '#ffffff'
        },
      options: {
        onPress: () => navigation.navigate('InvestorsDataScreen', {year: (new Date().getFullYear())})
      },
    },
    {
      id: 2 ,
      description: {
        text: t('login.title')
      }, 
      color: '#fff',
      background: '#00ff9f',
      icon: {
       name: 'user-md',
       size: 40,
       color: '#ffffff'
        },
      options: {
        onPress: () => navigation.navigate('LoginScreen')
      },
    },
    {
      id: 1,
      description: {
        text: '2021, 2022,...'
      },
      color: '#fff',
      background: '#fe1bb5',
      icon: {
       name: 'history',
       size: 40,
       color: '#ffffff'
        },
      options: {
        onPress: () => navigation.navigate('InvestorsDataScreen', {year: 2022})
      },
    },
    {
      id: 3 ,
      description: {
        text: t('cash.title')
      },
      color: '#fff',
      background: '#c900ff',
      icon: {
       name: 'money',
       size: 40,
       color: '#ffffff'
        },
      options: {
        onPress: () => navigation.navigate('TotalCashScreen')
      },
    },
    {
      id: 4 ,
      description: {
        text: t('members')
      },
      color: '#fff',
      background: '#f2df30',
      icon: {
       name: 'users',
       size: 40,
       color: '#ffffff'
        },
      options: {
        onPress: () => navigation.navigate('MembersScreen')
      },
    },
    {
      id: 5 ,
      description: {
        text: t('setting.title')
      },
      color: '#fff',
      background: '#00ccff',
      icon: {
       name: 'cog',
       size: 40,
       color: '#ffffff'
        },
      options: {
        onPress: () => navigation.navigate('SettingScreen')
      },
    },
    {
      id: 7,
      description: {
        text: t('calendar.title'),
        customStyle: {
          color: '#fff'
        }
      },
      color: '#fff',
      background: '#5d6a6d',
      icon: {
       name: 'calendar',
       size: 40,
       color: '#fff'
        },
      options: {
        onPress: () => navigation.navigate('CalendarScreen')
      },
    },
      {
      id: 8,
      description: {
        text: t('about.title')
      },
      color: '#fff',
      background: '#0013ff',
      icon: {
       name: 'info-circle',
       size: 40,
       color: '#ffffff'
        },
      options: {
        onPress: () => navigation.navigate('AboutScreen')
      },
    }
    ] 
    
  useEffect(() => {
    setTimeout(() => setLoading(false), 500)
  }, [])
   
if(isLoading)
 return <Loading />
else 
  return (
    <ImageBackground
       imageStyle={{opacity: 0.7}}
       source={{uri: 'https://firebasestorage.googleapis.com/v0/b/f-walewal-5d67b.appspot.com/o/fw-background.png?alt=media&token=6e7cb5cb-81f2-4f63-a217-bf5eed581645'}}
       style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <LinearGradient
        // Background Linear Gradient
        colors={['#00ccff', 'transparent']}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          height: '30%',
          zIndex: 0
        }}
      />
      <Panel data={panelData}/>
    </ImageBackground>
  );
}