import {
  Alert,
  Image,
  StyleSheet,
  Pressable,
  ImageBackground,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
  FlatList,
} from "react-native";
import { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Dimensions, Text, View } from "react-native";
//import styles from './styles'
import OpenLink from "../../util/OpenLink";
import { FontAwesome } from "@expo/vector-icons";
import DataItem from "../../DataItem";
import URLHelper from "../../URLHelper";
import Api from "../../api/Api";
import { LinearGradient } from "expo-linear-gradient";
import { useTranslation } from "react-i18next";
import NetInfo from "@react-native-community/netinfo";

const windowWidth = Dimensions.get("window").width;

export default function AdminProfileCard({ data }) {
  const [netStatus, setNetStatus] = useState(false);

  useEffect(() => {
    NetInfo.fetch().then((state) => {
      const netStatusValue = state.isConnected && state.isInternetReachable;
      setNetStatus(netStatusValue);
    });
  }, []);
  return (
    <View style={styles.panel}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        {data.map((item) => (
          <Pressable
            key={item.id}
            onPress={() => {}}
            android_ripple={{ color: item.color ? item.color : "black" }}
            style={[
              styles.itemPressEffect,
              {
                justifyContent:
                  item.icon && item.icon.description
                    ? "center"
                    : "space-evenly",
                backgroundColor: "transparent",
              },
            ]}
          >
            {item.icon.uri && (
              <View style={{ margin: 10, zIndex: 1 }}>
                {netStatus && (
                  <View
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: 17,
                      zIndex: 2,
                    }}
                  >
                    <FontAwesome
                      color="rgb(17,211,0)"
                      size={15}
                      name="dot-circle-o"
                    />
                  </View>
                )}
                <Image
                  style={{ width: 100, height: 100, borderRadius: 100 }}
                  source={{ uri: item.icon.uri }}
                />
              </View>
            )}
            {item.icon.name ? (
              <View
                style={[
                  styles.icon,
                  {
                    backgroundColor: item.background
                      ? item.background
                      : "e300ff99",
                  },
                ]}
              >
                {item.icon.name && (
                  <Text
                    style={[
                      styles.panelItemTitle,
                      { color: item.color ? item.color : "#fff" },
                    ]}
                  >
                    <FontAwesome
                      size={item.icon.size}
                      color={item.icon.color}
                      name={item.icon.name}
                    />
                  </Text>
                )}
              </View>
            ) : (
              ""
            )}
            <LinearGradient
              // Background Linear Gradient
              colors={[item.background ? item.background : "#fff", "#00ccff50"]}
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                //transform: [{scale: 3}],
                height: "100%",
              }}
            />
            <View style={{ flex: 1, backgroundColor: "#ffffff50" }}>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 5,
                  borderBottomColor: "#dbdbdb",
                  borderBottomWidth: 3,
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{ color: "#666", fontWeight: "bold", fontSize: 17 }}
                >
                  {item.userFullName}
                </Text>
                <FontAwesome
                  onPress={() => item.onPress()}
                  color="rgb(204,65,12)"
                  size={20}
                  name="power-off"
                />
              </View>

              <Text style={styles.cardItemText}>
                <FontAwesome size={20} name="user" /> {item.username}
              </Text>
              <Pressable
                onPress={() => {
                  OpenLink(`tel:` + item.tel);
                }}
              >
                <Text style={[styles.cardItemText, { color: "blue" }]}>
                  <FontAwesome size={20} name="phone" /> {item.tel}
                </Text>
              </Pressable>
              <Text style={styles.cardItemText}>
                <FontAwesome size={20} name="clock-o" />{" "}
                {new Date(item.time).toLocaleString()}
              </Text>
            </View>
          </Pressable>
        ))}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  panel: {
    flexDirection: "row",
    backgroundColor: "transparent",
  },
  cardItemText: {
    color: "#666",
    paddingHorizontal: 5,
    paddingVertical: 3,
  },
  panelItem: {
    overflow: "hidden",
    // borderRadius:8,
    margin: 10,
    elevation: 5,
  },
  panelItemTitle: {
    fontSize: 30,
    fontFamily: "monospace",
    fontWeight: "bold",
  },
  itemPressEffect: {
    minWidth: windowWidth,
    flexDirection: "row",
    marginBottom: 10,
    flexWrap: "wrap",
    overflow: "hidden",
  },
  icon: {
    borderWidth: 0.2,
    //elevation: 4,
    borderColor: "#5c5c5c",
    opacity: 0.8,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
  },
});
